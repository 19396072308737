import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MousePointerClick, Settings, Book, Star, Sparkles, Download } from 'lucide-react';
import mainImage from '../assets/image/main.jpeg'
import firstPageImage from "../assets/image/mobile/mobile2.png";

export default function Home() {
  const [sections] = useState(['hero', 'about', 'download']);
  const [scrollY, setScrollY] = useState(0);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const scrollToSection = (index: number) => {
    sectionRefs.current[index]?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollY]);

  return (
    <main className="relative">
      {/* Hero Section */}
      <section
        ref={el => sectionRefs.current[0] = el}
        className="relative h-screen flex items-center justify-center overflow-hidden perspective-1000"
        style={{ background: 'linear-gradient(135deg, rgba(93, 173, 236, 0.9) 0%, rgba(157, 101, 201, 0.7) 50%, rgba(255, 86, 120, 0.8) 100%)' }}
      >
        {/* Parallax Layers */}
        <div 
          className="absolute inset-0 bg-grid-pattern opacity-10 parallax-layer"
          style={{ transform: `translate3d(0, ${scrollY * 0.1}px, 0)` }}
        />
        <div 
          className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(99,102,241,0.15)_0%,transparent_100%)] parallax-layer"
          style={{ transform: `translate3d(0, ${scrollY * -0.2}px, 0)` }}
        />
        <div 
          className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(139,92,246,0.1)_0%,transparent_100%)] parallax-layer"
          style={{ transform: `translate3d(0, ${scrollY * 0.3}px, 0)` }}
        />

        {/* Top Bar */}


        <div className="absolute inset-0 bg-grid-pattern opacity-10"
             style={{ transform: `translateY(${scrollY * 0.5}px)` }} />

        <div className="relative z-10 max-w-4xl mx-auto px-4 text-center">
          <div className="bg-white/10 backdrop-blur-lg rounded-3xl p-12 border border-white/20">
            <div className="grid grid-cols-6 gap-4 mb-12">
                            <div></div>
              <div className="hex-tile">W</div>
              <div className="hex-tile">O</div>
              <div className="hex-tile">R</div>
              <div className="hex-tile">D</div>
              <div></div>
              <div className="hex-tile">S</div>
              <div className="hex-tile">P</div>
              <div className="hex-tile">A</div>
              <div className="hex-tile">C</div>
              <div className="hex-tile">E</div>
              <div className="hex-tile">S</div>
            </div>
            <p className="text-white/90 text-lg mb-8 leading-relaxed">
              Words are the building blocks of knowledge and communication. Through them, we explore the cosmos, 
              share discoveries, and connect with fellow space enthusiasts across the universe.
            </p>
            
          </div>
        </div>

        {/* Scroll Indicator */}
        <div 
          className="scroll-indicator flex flex-col items-center cursor-pointer"
          onClick={() => scrollToSection(1)}
        >
          <div className="text-white/60 text-sm mb-2">Scroll to explore</div>
          <MousePointerClick className="w-6 h-6 text-white/80" />
        </div>
      </section>

      {/* About Section */}
      <section
        ref={el => sectionRefs.current[1] = el}
        className="relative h-screen flex items-center bg-gradient-to-b from-purple-900 via-purple-custom/70 to-pink-custom/80 overflow-hidden"
        style={{ perspective: '1000px' }}
      >
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="floating-dots"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              opacity: 0
            }}
          />
        ))}
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1632507127024-ae3b69458a4c?auto=format&fit=crop&w=2000&q=80')] bg-cover bg-center opacity-10"
             style={{ transform: `translate3d(0, ${(scrollY - window.innerHeight) * 0.2}px, 0)` }} />
        <div className="relative z-10 max-w-7xl mx-auto px-4 py-16">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="space-y-8" data-aos="fade-right">
              <h2 className="text-4xl md:text-5xl font-bold text-white" data-aos="fade-up" data-aos-delay="100">
                About the Game
              </h2>
              <p className="text-xl text-white/90" data-aos="fade-up" data-aos-delay="200">
                Discover an immersive word puzzle experience that challenges your mind and expands your vocabulary.
              </p>
              <div className="space-y-6" data-aos="fade-up" data-aos-delay="300">
                <div className="flex items-center gap-3 bg-white/5 backdrop-blur-sm p-4 rounded-xl hover:bg-white/10 transition-all transform hover:translate-x-2">
                  <Sparkles className="w-6 h-6 text-yellow-400" />
                  <span className="text-white">Multiple difficulty levels</span>
                </div>
                <div className="flex items-center gap-3 bg-white/5 backdrop-blur-sm p-4 rounded-xl hover:bg-white/10 transition-all transform hover:translate-x-2">
                  <Star className="w-6 h-6 text-yellow-400" />
                  <span className="text-white">Daily challenges</span>
                </div>
              </div>
            </div>
            <div 
              className="relative perspective-1000" 
              data-aos="fade-left" 
              data-aos-delay="400"
            >
              <div 
                className="bg-white/10 backdrop-blur-md rounded-3xl p-6 border border-white/20 transform hover:scale-105 hover:rotate-2 transition-all duration-300"
                style={{
                  boxShadow: '0 0 40px rgba(139, 92, 246, 0.1)',
                }}
              >
                <img
                  src={mainImage}
                  alt="Game interface"
                  className="rounded-2xl shadow-lg w-56"
                  style={{float: 'left'}}
                />
                <img
                  src={firstPageImage}
                  alt="Game interface"
                  className="rounded-2xl shadow-lg w-56"
                  style={{float: 'right'}}
                />
                <div className="absolute inset-0 bg-gradient-to-tr from-purple-500/20 to-transparent rounded-3xl" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Download Section */}
      <section
        ref={el => sectionRefs.current[2] = el}
        className="relative h-screen flex items-center bg-gradient-to-b from-pink-custom/80 to-yellow-custom/60 overflow-hidden"
        style={{ perspective: '1000px' }}
      >
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1516414447565-b14be0adf13e?auto=format&fit=crop&w=2000&q=80')] bg-cover bg-center opacity-5"
             style={{ transform: `translate3d(0, ${(scrollY - window.innerHeight * 2) * 0.2}px, 0)` }} />
        <div className="relative z-10 max-w-4xl mx-auto px-4 text-center">
          <div className="bg-white/10 backdrop-blur-lg rounded-3xl p-12 border border-white/20">
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-8" data-aos="fade-up">
              Download Now
            </h2>
            <p className="text-xl text-white/90 mb-12" data-aos="fade-up" data-aos-delay="100">
              Available on iOS App Store
            </p>
            <div data-aos="fade-up" data-aos-delay="200">
            <a
                href="https://apps.apple.com/us/app/wordspaces-hidden-words/id6741804351"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-3 bg-white text-purple-600 px-8 py-4 rounded-xl hover:bg-white/90 transition-colors"
              >
                <Download className="w-6 h-6" />
                <div className="text-left">
                  <div className="text-sm">Download on the</div>
                  <div className="text-xl font-bold">App Store</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}