import { Star, Menu, X } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import logo from '../assets/image/logo.png'; 

export default function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 border-b ${
      isScrolled ? 'bg-white/90 backdrop-blur-sm border-white/10' : 'bg-transparent border-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-24">
          <div className="flex items-center">
            <button onClick={() => handleNavigation('/')} className="flex items-center">
            <img src={logo} alt="Space Words Logo" className="w-16" />
            </button>
          </div>
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <button onClick={() => handleNavigation('/features')} className="text-lg hover:text-sky-custom transition-colors">Features</button>
            <button onClick={() => handleNavigation('/gallery')} className="text-lg hover:text-sky-custom transition-colors">Gallery</button>
           {/* <button onClick={() => handleNavigation('/download')} className="text-lg hover:text-sky-custom transition-colors">Download</button> */}
            <a
              href="https://apps.apple.com/us/app/wordspaces-hidden-words/id6741804351"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-sky-custom hover:bg-sky-custom/80 px-6 py-3 text-lg rounded-lg transition-colors"
            >
              Get Started
            </a>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-white hover:text-blue-400"
            >
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <button onClick={() => handleNavigation('/features')} className="block w-full text-left px-3 py-2 hover:bg-blue-600/20 rounded-lg">Features</button>
            <button onClick={() => handleNavigation('/gallery')} className="block w-full text-left px-3 py-2 hover:bg-blue-600/20 rounded-lg">Gallery</button>
            <button onClick={() => handleNavigation('/download')} className="block w-full text-left px-3 py-2 hover:bg-blue-600/20 rounded-lg">Download</button>
          </div>
        </div>
      )}
    </nav>
  );
}