import { Download } from 'lucide-react';

export default function DownloadPage() {
  return (
    <section className="min-h-screen pt-24 bg-gradient-to-b from-gray-900 to-black flex items-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Explore?</h2>
        <p className="text-xl text-gray-400 mb-8">Download Space Words and start your astronomical journey today.</p>
        <button className="bg-blue-600 hover:bg-blue-700 px-8 py-3 rounded-lg inline-flex items-center transition-colors">
          <Download className="mr-2 h-5 w-5" />
          Download on App Store
        </button>
      </div>
    </section>
  );
}