import { Shield } from 'lucide-react';

export default function Privacy() {
  return (
    <div className="min-h-screen pt-24 bg-gradient-to-b from-sky-custom/90 via-purple-custom/70 to-pink-custom/80">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 border border-white/20">
          <div className="flex items-center gap-3 mb-8">
            <Shield className="h-8 w-8 text-sky-custom" />
            <h1 className="text-3xl font-bold">Privacy Policy</h1>
          </div>

          <div className="space-y-6 text-gray-300">
            <section>
              <h2 className="text-xl font-semibold text-white mb-3">Data Collection</h2>
              <p>We collect minimal data to provide and improve the Space Words experience. This includes:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Game progress and achievements</li>
                <li>Device information for compatibility</li>
                <li>Anonymous usage statistics</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">Data Usage</h2>
              <p>Your data helps us:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Sync your progress across devices</li>
                <li>Improve game features and performance</li>
                <li>Provide personalized content recommendations</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">Data Protection</h2>
              <p>We implement industry-standard security measures to protect your data:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>End-to-end encryption for sensitive data</li>
                <li>Regular security audits and updates</li>
                <li>Strict access controls and monitoring</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">Your Rights</h2>
              <p>You have the right to:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Access your personal data</li>
                <li>Request data deletion</li>
                <li>Opt-out of data collection</li>
                <li>Export your data</li>
              </ul>
            </section>

            <p className="text-sm text-gray-400 mt-8">Last updated: March 2025</p>
          </div>
        </div>
      </div>
    </div>
  );
}