import { useNavigate } from 'react-router-dom';
import logo from '../assets/image/logo.png';

export default function Footer() {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <footer className="bg-gray-500 border-t border-white/10 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-4 md:mb-0">
            <img src={logo} alt="Space Words Logo" className="w-16" />
          </div>
          <div className="flex space-x-6">
            <button onClick={() => handleNavigation('/privacy')} className="text-black hover:text-white transition-colors">Privacy</button>
            <button onClick={() => handleNavigation('/terms')} className="text-black hover:text-white transition-colors">Terms</button>
            <button onClick={() => handleNavigation('/contact')} className="text-black hover:text-white transition-colors">Contact</button>
          </div>
        </div>
        <div className="mt-4 text-center md:text-left text-gray-500">
          © 2025 Space Words. All rights reserved.
        </div>
      </div>
    </footer>
  );
}