import { Smartphone, Compass, Zap } from 'lucide-react';

export default function Features() {
  return (
    <section className="min-h-screen pt-24 bg-gradient-to-b from-sky-custom/90 via-purple-custom/70 to-pink-custom/80">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-16">Key Features</h2>
        
        <div className="grid md:grid-cols-3 gap-8 relative z-10" data-aos="fade-up" data-aos-delay="200">
          <div className="bg-white/10 p-6 rounded-xl backdrop-blur-sm transform transition-transform hover:scale-105 border border-white/20">
            <Smartphone className="h-12 w-12 text-blue-400 mb-4" />
            <h3 className="text-xl font-bold mb-2">Interactive Learning</h3>
            <p className="text-gray-400">Learn about space through interactive lessons, 3D models, and engaging quizzes.</p>
          </div>
          
          <div className="bg-white/10 p-6 rounded-xl backdrop-blur-sm transform transition-transform hover:scale-105 border border-white/20">
            <Compass className="h-12 w-12 text-blue-400 mb-4" />
            <h3 className="text-xl font-bold mb-2">Sky Guide</h3>
            <p className="text-gray-400">Point your device at the sky to identify stars, planets, and constellations in real-time.</p>
          </div>
          
          <div className="bg-white/10 p-6 rounded-xl backdrop-blur-sm transform transition-transform hover:scale-105 border border-white/20">
            <Zap className="h-12 w-12 text-blue-400 mb-4" />
            <h3 className="text-xl font-bold mb-2">Daily Updates</h3>
            <p className="text-gray-400">Get daily space news, astronomical events, and stunning NASA images of the day.</p>
          </div>
        </div>
      </div>
    </section>
  );
}