import { Scale } from 'lucide-react';

export default function Terms() {
  return (
    <div className="min-h-screen pt-24 bg-gradient-to-b from-purple-custom/90 via-pink-custom/70 to-yellow-custom/60">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 border border-white/20">
          <div className="flex items-center gap-3 mb-8">
            <Scale className="h-8 w-8 text-sky-custom" />
            <h1 className="text-3xl font-bold">Terms of Service</h1>
          </div>

          <div className="space-y-6 text-gray-300">
            <section>
              <h2 className="text-xl font-semibold text-white mb-3">License Agreement</h2>
              <p>By downloading and using Space Words, you agree to:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Use the app for personal, non-commercial purposes</li>
                <li>Not modify, reverse engineer, or decompile the app</li>
                <li>Not distribute or transfer the app to unauthorized users</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">User Conduct</h2>
              <p>Users must:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Respect intellectual property rights</li>
                <li>Not engage in harmful or disruptive behavior</li>
                <li>Maintain the security of their account</li>
                <li>Report any violations or security concerns</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">Content Guidelines</h2>
              <p>All user-generated content must:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Be appropriate for all ages</li>
                <li>Not violate any laws or regulations</li>
                <li>Respect other users' rights and privacy</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">Termination</h2>
              <p>We reserve the right to:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Suspend or terminate accounts for violations</li>
                <li>Modify or discontinue services</li>
                <li>Update these terms with reasonable notice</li>
              </ul>
            </section>

            <p className="text-sm text-gray-400 mt-8">Last updated: March 2025</p>
          </div>
        </div>
      </div>
    </div>
  );
}