import mainImage from "../assets/image/main.jpeg";
import mobile1 from "../assets/image/mobile/mobile1.png";
import mobile2 from "../assets/image/mobile/mobile2.png";
import mobile3 from "../assets/image/mobile/mobile3.png";
import mobile4 from "../assets/image/mobile/mobile4.png";
import mobile5 from "../assets/image/mobile/mobile5.png";
import mobile6 from "../assets/image/mobile/mobile6.png";
import mobile7 from "../assets/image/mobile/mobile7.png";
 
export default function Gallery() {
  return (
    <section className="min-h-screen pt-24 bg-gradient-to-b from-purple-custom/90 via-pink-custom/70 to-yellow-custom/60">
      <div className="max-w-7xl mx-auto p-5 sm:px-6 lg:px-8">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-16">App Screenshots</h2>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 relative z-10" data-aos="fade-up" data-aos-delay="400">
          {[
            mainImage,
            mobile1,
            mobile2,
            mobile3,
            mobile4,
            mobile5,
            mobile6,
            mobile7
          
          ].map((url, index) => (
            <div key={index} className="relative group overflow-hidden rounded-lg max-w-[280px] mx-auto">
              <img
                src={url}
                alt={`Gallery image ${index + 1}`}
                className="w-full h-[560px] object-cover transform transition-transform duration-300 group-hover:scale-110"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}